import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";


import GPLogoBlack from "../../assets/wilma-logo-black.svg";
import useNotification from "../../components/notifications/useNotifications";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import FileUpload from "../../components/shared/Inputs/FileUpload";
import { TextInput } from "../../components/shared/Inputs/TextInput";
import LoadingWheel from "../../components/shared/LoadingWheel";
import useCustomer from "../../data/useCustomer";

export default function CustomerWebForm({ }: {}) {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { uploadDocs, updateLead } = useCustomer();
  const { addNotification } = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [noLead, setNoLead] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [carImages, setCarImages] = useState([]);
  const [idImages, setIdImages] = useState([]);
  const [miscImages, setMiscImages] = useState([]);

  const updateUploadedCarFiles = (files: any) => setCarImages({ ...files });
  const updateUploadedIdFiles = (files: any) => setIdImages({ ...files });
  const updateUploadedMiscFiles = (files: any) => setMiscImages({ ...files });

  const onSubmit = (data: any) => {
    if (Object.keys(carImages).length > 10) {
      addNotification({
        variant: "error",
        primaryText: "You cannot upload more than 10 images of the car"
      })
      return;
    }

    if (Object.keys(idImages).length > 5) {
      addNotification({
        variant: "error",
        primaryText: "You cannot upload more than 5 images of your identification"
      })
      return;
    }

    if (Object.keys(miscImages).length > 5) {
      addNotification({
        variant: "error",
        primaryText: "You cannot upload more than 5 miscellaneous images"
      })
      return;
    }
    setIsLoading(true);
    setIsSubmitting(true);
    handleUpload(data);
  }

  const handleUpload = (data: any) => {

    //attach files to http request
    const formData = new FormData();

    for (let i = 0; i < Object.keys(carImages).length; i++) {
      formData.append("Vehicle", carImages[i]);
    }

    for (let i = 0; i < Object.keys(idImages).length; i++) {
      formData.append("Identity", idImages[i]);
    }

    for (let i = 0; i < Object.keys(miscImages).length; i++) {
      formData.append("Misc", miscImages[i]);
    }

    //Validating on leadId and emailId
    const leadId = searchParams.get("leadId")
    // const emailId = searchParams.get("email")
    // console.log(leadId, emailId)
    //Create objects
    // let updatedLead = {
    //   leadId: leadId,
    //   emailId: emailId,
    //   ...data,
    // };

    let docsBody = {
      leadId: leadId,
      // emailId: emailId,
      data: formData,
    }

    if (Array.from(docsBody.data).length < 1) {
      setIsLoading(false);
      return;
    }

    handleDocsBody(docsBody);
  }

  const handleDocsBody = (docsBody: any) => {

    uploadDocs.mutate(docsBody, {
      onSuccess: () => {
        console.log("Success")
        setIsFinished(true);
        setIsSubmitting(false);
        setIsLoading(false);
      },
      onError: (error) => {
        console.log("Error: ", error);
        addNotification({
          variant: "error",
          primaryText: "Unable to upload documents"
        });
        setIsSubmitting(false);
        setIsLoading(false);
      }
    });
  }

  useEffect(() => {
    //get lead and email ids from query string
    if (!searchParams.get("leadId")) {
      setNoLead(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }

  }, [])

  if (isLoading) {
    return (
      <div className="pt-[20vh]">
        <LoadingWheel />
        {isSubmitting && <div className="mt-10 text-xl font-semibold w-full text-center">Uploading documents, this could take a few minutes, please don't close this window!</div>}
      </div>
    );
  } else if (noLead) {
    return (
      <div className="pt-[20vh]">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-20 w-auto"
            src={GPLogoBlack}
            alt="GP Logo"
          />
        </div>
        <div className="text-xl pt-10 font-semibold w-full text-center">An error ocurred, if this problem persists, please contact support!</div>
      </div>
    );
  } else if (isFinished) {
    return (
      <div className="pt-[20vh]">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-20 w-auto"
            src={GPLogoBlack}
            alt="GP Logo"
          />
        </div>
        <div className="text-xl pt-10 font-semibold w-full text-center">Thank you for submitting your documents!</div>
      </div>
    );
  } else {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-20 w-auto"
              src={GPLogoBlack}
              alt="GP Logo"
            />
          </div>

          <div className="mt-8 grid px-2 sm:mx-auto sm:w-full sm:max-w-6xl gap-y-4 py-10">
            {/* <h1 className="font-semibold text-xl text-center">Customer Information</h1> */}

            {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <TextInput label="Account Name" register={register} registerName="accountName" />
              <TextInput label="Account Number" register={register} registerName="accountNumber" />
              <TextInput label="Sort Code" register={register} registerName="sortCode" />
            </div> */}

            <FileUpload accept=".jpg,.png,.jpeg,.pdf" label="Images of Vehicle" desc="Maximum of 10" multiple updateFilesCb={updateUploadedCarFiles} />
            <FileUpload accept=".jpg,.png,.jpeg,.pdf" label="Identification" desc="Maximum of 5" multiple updateFilesCb={updateUploadedIdFiles} />
            <FileUpload accept=".jpg,.png,.jpeg,.pdf" label="Miscellaneous" desc="Pictures of entrance to property if hard to find etc. - 5 max" multiple updateFilesCb={updateUploadedMiscFiles} />


            <ButtonInput classes="w-28 text-center" label="Submit" isSubmit={true} />
          </div>

        </div>
      </form>
    );
  }
}