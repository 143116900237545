import { Switch } from "@headlessui/react";
import classNames from "../../utils/classNames";
import { IPostcodePrice } from "../../models/PostcodeGroup";
import { useEffect, useState } from "react";
import usePostcodePriceMutations from "../../data/usePostcodePriceMutations";
import useDefaultCRUDHandlers from './../../hooks/useDefaultCRUDHandlers';

export default function EditPostcodeAreaEngine({ postcodeArea }: { postcodeArea: IPostcodePrice }) {
    const { update } = usePostcodePriceMutations();
    const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Postcode Area");
    const [engine, setEngine] = useState<boolean>(postcodeArea.onlyGoodEngineCondition);

    useEffect(() => {
        if (postcodeArea.onlyGoodEngineCondition === engine) return;
        setEngine(postcodeArea.onlyGoodEngineCondition);
    }, [postcodeArea.onlyGoodEngineCondition]);

    const handleEngineChange = (checked : boolean) => {
        if (postcodeArea.onlyGoodEngineCondition === checked) return;
        setEngine(checked);
        setTimeout(() => {
            update.mutate({
                ...postcodeArea,
                onlyGoodEngineCondition: checked
            }, {
                ...saveHandlers
            });
        }, 500);  
        
    }
    
    return (
        <Switch
            checked={engine}
            onChange={(e) => handleEngineChange(e)}
            className={classNames(
                engine ? 'bg-primary-600' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
            )}
        >
            <span
            aria-hidden="true"
            className={classNames(
                engine ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
            />
        </Switch>
    )
}
