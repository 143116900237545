import Modal from "../../../layouts/Modal";
import { IManufacturerPriceSetting } from "../../../models/PriceSettings";

import ManufacturerPriceSingle from "./ManufacturerPriceSingle";

interface ManufacturerPriceModalProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    customerPriceConfigurationId: string,
    manufacturerPriceSetting?: IManufacturerPriceSetting;
}

export default function ManufacturerPriceModal({ open, setOpen, manufacturerPriceSetting, customerPriceConfigurationId }: ManufacturerPriceModalProps) {
    return (
        <Modal width="max-w-6xl" open={open} setOpen={setOpen} >
            <h1 className="text-xl">{`${manufacturerPriceSetting ? "Edit" : "Add"} Manufacturer Price Setting`}</h1>
            
            <div className="mt-4">
                <div className="space-y-4 border-t border-gray-200" >
                    <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-1">
                        {manufacturerPriceSetting
                        ?
                            <ManufacturerPriceSingle 
                                customerPriceConfigurationId={customerPriceConfigurationId} 
                                priceSetting={manufacturerPriceSetting} 
                                onSuccess={() => setOpen(false)}
                            />
                        :
                            <ManufacturerPriceSingle 
                                customerPriceConfigurationId={customerPriceConfigurationId} 
                                onSuccess={() => setOpen(false)}
                            />
                        }
                        
                    </div>
                </div>
            </div>
        </Modal>    
    )
}