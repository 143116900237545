import { createColumnHelper, flexRender, getCoreRowModel, getExpandedRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import usePostcodeGroupSingle from "../../data/usePostcodeGroupSingle";
import { IPostcodePrice } from "../../models/PostcodeGroup";
import { Fragment, useState } from "react";
import EditPostcodeAreaPrice from "./EditPostcodeAreaPrice";
import RemovePostcodeAreaPrice from "./RemovePostcodeAreaPrice";
import { Switch } from "@headlessui/react";
import classNames from "../../utils/classNames";
import EditPostcodeAreaEngine from "./EditPostcodeAreaEngine";
import EditPostcodeAreaBody from "./EditPostcodeAreaBody";



export default function PostcodeAreasTable({ postcodePrices, outcode }: { postcodePrices: IPostcodePrice[], outcode: string }) {
  const [sorting, setSorting] = useState<SortingState>([{ id: "postcode", desc: false }]);


  const colHelper = createColumnHelper<IPostcodePrice>();

  const cols = [
    // colHelper.display({
    //   id: "name",
    //   header: "Name",
    //   sortingFn: "auto",
    //   cell: (info) => (
    //     <span className="flex  " >
    //       <span className="ml-4 m-auto">{info.row.original.name}</span>
    //     </span>
    //   )
    // }),
    colHelper.accessor("postcode", {
      header: "Postcode",
      id: "postcode",
      cell: (info) => (<span>{outcode}{info.getValue()}</span>)
    }),
    colHelper.display({
      header: "Price per Tonne (£)",
      cell: (info) => (
        <EditPostcodeAreaPrice postcodeArea={info.row.original} />
      )
    }),
    colHelper.display({
      header: "Engine",
      cell: (info) => (
        <EditPostcodeAreaEngine postcodeArea={info.row.original} />
      )
    }),
    colHelper.display({
      header: "Body",
      cell: (info) => (
        <EditPostcodeAreaBody postcodeArea={info.row.original} />
      )
    }),
    colHelper.display({
      header: "Delete",
      cell: (info) => (
        <RemovePostcodeAreaPrice postcodeArea={info.row.original} />
      )
    }),
  ];
  


  const table = useReactTable({
    data: postcodePrices,
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });


  return (



    <div className="mt-4 bg-white overflow-auto">
      <table className="min-w-full overflow-scroll divide-y border divide-gray-300">

        {/* Table headings - very similar to all the other tables */}
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="bg-gp-blue-50 text-gray-800">
              {headerGroup.headers.map((header, i) => (
                <th
                  key={header.id}
                  scope="col"
                  className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                >
                  {header.isPlaceholder
                    ? null
                    : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' ▲',
                          desc: ' ▼',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>

                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {/* Body */}
        <tbody>
          {table.getRowModel().rows.map((row, i) => {
            let rowCells = row.getVisibleCells();
            return (
              <Fragment key={i}>
                <tr
                  key={row.id}
                  // onClick={() => setPostcodeGroupPrice(row.original)}
                  className={"hover:bg-gray-100"}
                >
                  {rowCells.map((cell) => (
                    <td key={cell.id} className="px-3 py-3 border text-sm">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
                {row.getIsExpanded() && (
                  <></>
                  // <tr>
                  //   {/* 2nd row is a custom 1 cell row */}
                  //   <td colSpan={row.getVisibleCells().length}>
                  //     <LeadsTableSubComponent lead={row.original} />
                  //   </td>
                  // </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>

      </table>
    </div>
  )
}