import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IManufacturerPriceSetting, IManufacturerPriceSettingResponse } from '../models/PriceSettings';

export default function useManufacturerPriceSettingSingle({ manufacturerPriceSettingId }: { manufacturerPriceSettingId?: string }) {
    const { get, put, post, del } = useApiHelper();
    const queryClient = useQueryClient();

    const baseManufacturerPriceSetting = useQuery({
        queryKey: ["manufacturerPriceSettings"],
        queryFn: () => get<IManufacturerPriceSettingResponse>("/PriceSetting/manufacturer/base"),
        refetchInterval: 1000 * 10,
        // enabled: !manufacturerPriceSettingId,
    });

    const manufacturerPriceSetting = useQuery({
        queryKey: ["manufacturerPriceSettings", manufacturerPriceSettingId],
        queryFn: () => get<IManufacturerPriceSettingResponse>(`/PriceSetting/manufacturer/${manufacturerPriceSettingId}`),
        refetchInterval: 1000 * 10,
        enabled: !!manufacturerPriceSettingId,
    });

    const create = useMutation(
        (priceSetting: IManufacturerPriceSetting) => post(`/PriceSetting/manufacturer`, priceSetting),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["manufacturerPriceSettings"]);
                queryClient.invalidateQueries(["priceSettings"]);
            },
        }
    );

    const update = useMutation(
        (priceSetting: IManufacturerPriceSetting) => put(`/PriceSetting/manufacturer/${priceSetting.id}`, priceSetting),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["manufacturerPriceSettings"]);
                queryClient.invalidateQueries(["priceSettings"]);
            },
        }
    );

    const deleteSetting = useMutation(
        (id: string) => del(`/PriceSetting/manufacturer/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["manufacturerPriceSettings"]);
                queryClient.invalidateQueries(["priceSettings"]);
            },
        }
    );

    return {
        baseManufacturerPriceSetting,
        manufacturerPriceSetting,
        update,
        create,
        deleteSetting
    };
}