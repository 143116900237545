import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IQuote, IQuotesResponse } from "../models/Quote";

export default function useQuotes() {
    const { get, post, put, del } = useApiHelper();
    const queryClient = useQueryClient();

    // const accepted = useQuery({
    //     queryKey: ["quotes"],
    //     queryFn: () => get<IQuotesResponse>("/quote/accepted"),
    //     refetchInterval: 1000 * 10,
    // });

    // const missed = useQuery({
    //     queryKey: ["quotes"],
    //     queryFn: () => get<IQuotesResponse>("/quote/missed"),
    //     refetchInterval: 1000 * 10,
    // });

    const abandoned = useQuery({
        queryKey: ["quotes"],
        queryFn: () => get<IQuotesResponse>("/quote/abandoned"),
        refetchInterval: 1000 * 10,
    });

    return {
        // accepted,
        // missed,
        abandoned,
    }
}