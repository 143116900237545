import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import LoadingWheel from "../../components/shared/LoadingWheel";
import usePriceSettings from "../../data/usePriceSettings";
import { RadioGroup } from "@headlessui/react";
import classNames from "../../utils/classNames";
import GeneralPriceSettings from "../../components/priceSettings/GeneralPriceSettings";
import SpecificPriceSettings from "../../components/priceSettings/SpecificPriceSettings";
import SalvagePriceSettings from "../../components/priceSettings/SalvagePriceSettings";
import ManufacturerPriceSettings from "../../components/priceSettings/ManufacturerPriceSettings";

export default function PriceSettings() {
  const { setInfo } = usePageNameContext();
  const { priceSettings } = usePriceSettings();
  const [isLoading, setIsLoading] = useState(true);
  const [viewType, setViewType] = useState(0);
  const viewTypes = [
    { value: 0, label: "General" },
    { value: 1, label: "Specific" },
    { value: 2, label: "Salvage" },
    { value: 3, label: "Tradepoor" },
  ];

  useEffect(() => {
    setInfo({
      name: "Price Settings",
      desc: "Configure the prices for different services",
    });
  }, []);

  useEffect(() => {
    if (!priceSettings.isLoading && priceSettings.data) {
     

      setIsLoading(false);
    }
  }, [priceSettings.isLoading, priceSettings.data]);

  return isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <div>
      <RadioGroup value={viewType} onChange={setViewType} className="my-4">
        <RadioGroup.Label className="sr-only">
          Choose which view to display
        </RadioGroup.Label>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-3">
          {viewTypes.map((type) => (
            <RadioGroup.Option
              key={`${type.value}`}
              value={type.value}
              className={({ active, checked }) =>
                classNames(
                  active ? "ring-2 ring-offset-2 ring-gp-blue-500" : "",
                  checked
                    ? "bg-primary-600 border-transparent text-white hover:bg-gp-blue-700"
                    : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                  "cursor-pointer focus:outline-none border rounded-md py-1.5 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                )
              }
            >
              <RadioGroup.Label as="span">{type.label}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      {{
        0: <GeneralPriceSettings />,
        1: <SpecificPriceSettings />,
        2: <SalvagePriceSettings />,
        3: <ManufacturerPriceSettings />,
      }[viewType]}
    </div>
  )

}