import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";

import date from "date-and-time";
import { useNavigate } from "react-router-dom";

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";


import { IQuote } from "../../models/Quote";
import classNames from "../../utils/classNames";
import Pagination from "../shared/Tables/Pagination";
import openInNewTab from "../../utils/openInNewTab";

const colHelper = createColumnHelper<IQuote>();

const cols = [
    colHelper.accessor("vehicleRegistration", {
        header: "VRM",
        enableSorting: false,
    }),
    colHelper.accessor("vehicleMake", {
        header: "Make",
        enableSorting: false,
    }),
    colHelper.accessor("vehicleModel", {
        header: "Model",
        enableSorting: false,
    }),
    colHelper.display({
        id: "collection",
        header: "Collection",
        sortingFn: "auto",
        cell: (info) => (
          <span className="flex" >
            <span className="ml-4 m-auto">
                {info.row.original.priceCollected != -1
                ? `£${info.row.original.priceCollected.toFixed(2)}`
                : `-`
                }
            </span>
          </span>
        )
    }),
    colHelper.display({
        id: "dropOff",
        header: "Drop Off",
        sortingFn: "auto",
        cell: (info) => (
          <span className="flex" >
            <span className="ml-4 m-auto">
                {info.row.original.priceDropOff != -1
                ? `£${info.row.original.priceDropOff.toFixed(2)}`
                : `-`
                }
            </span>
          </span>
        )
    }),
    colHelper.accessor("createdDate", {
        header: "Created Date",
        sortingFn: "auto",
        cell: (created) => (
          <span>
            {date.format(new Date(created.getValue()!), "DD/MM/YYYY")}
          </span>
        ),
    }),
];

export default function QuotesTable({ data }: {data: IQuote[]}) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const table = useReactTable({
        data: data ?? [],
        columns: cols,
        state: {
          sorting: sorting,
        },
        enableRowSelection: true,
        getExpandedRowModel: getExpandedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    useEffect(() => {
        table.setPageSize(50);
    }, []);

    const handleClassNames = (quote: IQuote) => {
        let classNames = "";
    
        classNames = "bg-green-200/80"  

        return classNames;
    };

    return (
        <>
            <div className="mt-4 bg-white overflow-auto">
                <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
                    <thead>
                    {table.getHeaderGroups()?.map((headerGroup) => (
                        <tr
                        key={headerGroup.id}
                        className="bg-secondary-50 text-gray-800"
                        >
                        {headerGroup.headers.map((header, i) => (
                            <th
                            key={header.id}
                            scope="col"
                            className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                            >
                            {header.isPlaceholder ? null : (
                                <div
                                {...{
                                    className: header.column.getCanSort()
                                    ? "cursor-pointer select-none"
                                    : "",
                                    onClick: header.column.getToggleSortingHandler(),
                                }}
                                >
                                {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}
                                {{
                                    asc: " ▲",
                                    desc: " ▼",
                                }[header.column.getIsSorted() as string] ?? null}
                                </div>
                            )}
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map((row, i) => {
                        let rowCells = row.getVisibleCells();
                        return (
                        <Fragment key={i}>
                            <tr
                            key={row.id}
                            onClick={() => {}}
                            className={classNames(
                                handleClassNames(row.original),
                                "hover:bg-gray-100 hover:cursor-pointer"
                            )}
                            >
                            {rowCells.map((cell) => (
                                <td key={cell.id} className="px-3 py-3 border text-sm">
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                                </td>
                            ))}
                            </tr>
                        </Fragment>
                        );
                    })}
                    </tbody>
                </table>
                <Pagination table={table} />
            </div>
        </>
    )
}