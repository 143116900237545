import { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";

import {
  ArchiveBoxArrowDownIcon,
  ArchiveBoxIcon,
  ArrowUturnUpIcon,
  BanknotesIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  CameraIcon,
  ChatBubbleLeftEllipsisIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  Cog6ToothIcon,
  Cog8ToothIcon,
  CurrencyPoundIcon,
  DocumentCurrencyPoundIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  FolderPlusIcon,
  XCircleIcon,
  HomeIcon,
  InboxStackIcon,
  MagnifyingGlassPlusIcon,
  NewspaperIcon,
  TagIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";

import Topbar from "../components/shared/Navbar/Topbar";
import LoadingWheel from "../components/shared/LoadingWheel";
import { NavigationItemInterface } from "../types/Navigation";
import { usePageNameContext } from "../contexts/PageTitleContext";
import MobileSidebar from "../components/shared/Navbar/MobileSidebar";
import DesktopSidebar from "../components/shared/Navbar/DesktopSidebar";
import { useAuth } from "../contexts/AuthContext";

import { useSearchParams } from "react-router-dom";

const staffNavigation: NavigationItemInterface[] = [
  // { name: "Dashboard", to: "/", icon: HomeIcon },

  { name: "Leads", to: "/", icon: FolderPlusIcon, group: "Leads" },
  { name: "Scrap Price", to: "scrap-price", icon: DocumentCurrencyPoundIcon, group: "Leads" },
  // { name: "Collected", to: "vehicles-collected", icon: ArchiveBoxIcon, group: "Leads" },
  // //{ name: "Calendar", to: "calendar", icon: CalendarDaysIcon },
  // { name: "Trip Manager", to: "trip-manager", icon: CalendarDaysIcon, group: "Leads" },
  // { name: "Payments", to: "payments", icon: BanknotesIcon, group: "Leads" },
  // { name: "Schedule", to: "schedule", icon: NewspaperIcon, group: "Leads" },

  // { name: "Awaiting Inventory", to: "parts-manager", icon: WrenchScrewdriverIcon, group: "Parts", disabled: true },
  // { name: "Awaiting Arrival", to: "awaiting-arrival", icon: ClockIcon, group: "Parts", disabled: true },
  // { name: "Parts Checker", to: "parts-checker", icon: ClipboardDocumentCheckIcon, group: "Parts", disabled: true },
  // { name: "Jobs", to: "jobs", icon: BriefcaseIcon, group: "Parts", disabled: true },
  // { name: "Stock", to: "stock", icon: InboxStackIcon, group: "Parts", disabled: true },

  // { name: "eBay Unlisted", to: "ebay-unlisted", icon: DocumentTextIcon, group: "eBay", disabled: true },
  // { name: "eBay Listings", to: "ebay-listings", icon: CurrencyPoundIcon, group: "eBay", disabled: true },
  // { name: "eBay Orders", to: "ebay-orders", icon: ArchiveBoxArrowDownIcon, group: "eBay", disabled: true },
  // { name: "eBay Returns", to: "ebay-returns", icon: ArrowUturnUpIcon, group: "eBay", disabled: true },

  // { name: "Vehicles", to: "vehicles", icon: TruckIcon },
  // { name: "Contacts", to: "contacts", icon: UsersIcon },
];

const driverNavigation: NavigationItemInterface[] = [
  // { name: "Find Lead", to: "find-lead", icon: DocumentMagnifyingGlassIcon },
];

const adminNavigation: NavigationItemInterface[] = [
  // { name: "Dashboard", to: "/", icon: HomeIcon },

  { name: "Leads", to: "/", icon: FolderPlusIcon, group: "Leads" },
  { name: "Scrap Price", to: "scrap-price", icon: DocumentCurrencyPoundIcon, group: "Leads" },
  { name: "Abandoned Quotes", to: "abandoned-quotes", icon: XCircleIcon, group: "Leads" },
  { name: "Price Settings", to: "price-settings", icon: Cog8ToothIcon, group: "Leads" },

  // { name: "Collected", to: "vehicles-collected", icon: ArchiveBoxIcon, group: "Leads" },
  // //{ name: "Calendar", to: "calendar", icon: CalendarDaysIcon, group: "Leads" },
  // { name: "Trip Manager", to: "trip-manager", icon: CalendarDaysIcon, group: "Leads" },
  // { name: "Payments", to: "payments", icon: BanknotesIcon, group: "Leads" },
  // { name: "Schedule", to: "schedule", icon: NewspaperIcon, group: "Leads" },

  // { name: "Awaiting Inventory", to: "parts-manager", icon: WrenchScrewdriverIcon, group: "Parts", disabled: true },
  // { name: "Awaiting Arrival", to: "awaiting-arrival", icon: ClockIcon, group: "Parts", disabled: true },
  // { name: "Parts Checker", to: "parts-checker", icon: ClipboardDocumentCheckIcon, group: "Parts", disabled: true },
  // { name: "Jobs", to: "jobs", icon: BriefcaseIcon, group: "Parts", disabled: true },
  // { name: "Stock", to: "stock", icon: InboxStackIcon, group: "Parts", disabled: true },

  // { name: "eBay Unlisted", to: "ebay-unlisted", icon: DocumentTextIcon, group: "eBay", disabled: true },
  // { name: "eBay Listings", to: "ebay-listings", icon: CurrencyPoundIcon, group: "eBay", disabled: true },
  // { name: "eBay Orders", to: "ebay-orders", icon: ArchiveBoxArrowDownIcon, group: "eBay", disabled: true },
  // { name: "eBay Returns", to: "ebay-returns", icon: ArrowUturnUpIcon, group: "eBay", disabled: true },
  // { name: "eBay Messages", to: "ebay-messages", icon: ChatBubbleLeftEllipsisIcon, group: "eBay", disabled: true },

  // { name: "Find Lead", to: "find-lead", icon: DocumentMagnifyingGlassIcon, group: "Admin" },
  // { name: "Category Editor", to: "category-editor", icon: TagIcon, group: "Admin" },
  // { name: "Required Images", to: "category-images", icon: CameraIcon, group: "Admin" },
  // { name: "Driver Tracking", to: "driver-tracking", icon: MagnifyingGlassPlusIcon, group: "Admin" },
  { name: "Admin Settings", to: "admin", icon: Cog6ToothIcon, group: "Admin" },
  // { name: "Vehicles", to: "vehicles", icon: TruckIcon },
  // { name: "Contacts", to: "contacts", icon: UsersIcon },
];

export default function DashboardLayout() {
  const {
    pageInfo: { name, desc },
  } = usePageNameContext();
  const { loading, loggedIn, claims } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [embedded, setEmbedded] = useState(false);

  useEffect(() => {
    const embed = searchParams.get("embed");
    if (embed) setEmbedded(embed == "true");
  }, [searchParams]);

  const getNavigation = () => {
    if (claims) {
      if (claims.isAdmin) {
        return adminNavigation;
      } else if (claims.isStaff) {
        return staffNavigation;
      } else if (claims.isDriver) {
        return driverNavigation;
      }
    }

    return [];
  };

  // console.log(loading || !loggedIn);

  const pageContent = (
    <div className={`flex flex-1 flex-col ${!embedded && "md:pl-60"}`}>
      {!embedded && <Topbar setSidebarOpen={setSidebarOpen} />}
      <main>
        <div className="py-6">
          <div className="mt-2 px-4 sm:px-6 md:pl-20 prose">
            <h1 className="font-semibold text-gray-900 mb-0">{name}</h1>
            <h4 className="text-base font-medium text-gray-500 mt-1">
              {desc}
            </h4>
          </div>
          <div className="px-4 sm:px-6 md:px-20">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  )

  if(loading || !loggedIn) return (
    <div className="h-screen w-screen flex items-center justify-center">
      <LoadingWheel />
    </div>
  );

  if (embedded) return pageContent;

  return loading || !loggedIn ? (
    <div className="h-screen w-screen flex items-center justify-center">
      <LoadingWheel />
    </div>
  ) : (
    <div>
      <MobileSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigation={getNavigation()}
      />
      <DesktopSidebar navigation={getNavigation()} />
      {pageContent}
      {/* <div className="flex flex-1 flex-col md:pl-60">
        <Topbar setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="py-6">
            <div className="mt-2 px-4 sm:px-6 md:pl-20 prose">
              <h1 className="font-semibold text-gray-900 mb-0">{name}</h1>
              <h4 className="text-base font-medium text-gray-500 mt-1">
                {desc}
              </h4>
            </div>
            <div className="px-4 sm:px-6 md:px-20">
              <Outlet />
            </div>
          </div>
        </main>
      </div> */}
    </div>
  );
}
