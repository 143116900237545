import { useEffect, useState } from "react";
import ManufacturerPriceSingle from "./Manufacturer/ManufacturerPriceSingle";
import Card from "../shared/Card/Card";
import usePriceSettings from "../../data/usePriceSettings";
import {
    IManufacturerPriceSetting
} from "../../models/PriceSettings";
import useManufacturerPriceSettingSingle from "../../data/useManufacturerPriceSettingSingle";
import LoadingWheel from "../shared/LoadingWheel";
import { PlusSmallIcon } from "@heroicons/react/20/solid";
import ManufacturerPriceModal from "./Manufacturer/ManufacturerPriceModal";
import ManufacturerPriceTable from "./Manufacturer/ManufacturerPriceTable";


export default function ManufacturerPriceSettings() {
    const { priceSettings } = usePriceSettings();
    const { baseManufacturerPriceSetting } = useManufacturerPriceSettingSingle({ manufacturerPriceSettingId: undefined });
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [editSetting, setEditSetting] = useState<IManufacturerPriceSetting>();
    const [manufacturerPriceSettings, setManufacturerPriceSettings] = useState<
        IManufacturerPriceSetting[]
    >([]);
    
    useEffect(() => {
        if (!priceSettings.isLoading && priceSettings.data) {
            if (priceSettings.data?.data.manufacturerPriceSettings) {
                setManufacturerPriceSettings(priceSettings.data?.data.manufacturerPriceSettings);
            }
            if(!baseManufacturerPriceSetting.isLoading && baseManufacturerPriceSetting.data) {
                setIsLoading(false);
            }
        }
    }, [priceSettings.isLoading, priceSettings.data, baseManufacturerPriceSetting.data, baseManufacturerPriceSetting.isLoading]);

    const handleSetManufacturerPriceSetting = (manufacturerPriceSetting: IManufacturerPriceSetting) => {
        setEditSetting(manufacturerPriceSetting);
        setModalOpen(true);
    }
    
    const handleAddManufacturerPriceSetting = () => {
        setEditSetting(undefined);
        setModalOpen(true);
    }

    if (isLoading) return <LoadingWheel />

    return (
        <>
            <ManufacturerPriceModal
                open={modalOpen}
                setOpen={setModalOpen}
                customerPriceConfigurationId={priceSettings.data?.data.customerPriceConfiguration?.id!}
                manufacturerPriceSetting={editSetting}
            />

            <div className="space-y-4">
                <Card>
                    <div className="pt-4 pb-2" >
                        <h1 className="font-semibold text-center mb-2">Minimum tradepoor to apply to salvage</h1>
                        <div className="flex justify-center">
                        <ManufacturerPriceSingle 
                            customerPriceConfigurationId={priceSettings.data?.data.customerPriceConfiguration?.id!} 
                            priceSetting={baseManufacturerPriceSetting?.data?.data} 
                            onSuccess={() => { }} 
                        />
                        </div>
                    </div>
                </Card>
            </div>

            <div className="mt-4 flex justify-between">

                <button
                    className=" bg-primary-600 hover:bg-primary-700 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
                    type="button"
                    onClick={() => handleAddManufacturerPriceSetting()}
                >
                    <PlusSmallIcon className="h-5 w-5 mr-2" />
                    Add New Setting
                </button>

            </div>

            <div className="bg-white shadow rounded p-4 mt-4">
            
                <h1 className="text-xl font-semibold">Manufacturer Tradepoor Settings</h1>
                <hr />
        
                <div className="flex flex-col divide-y gap-y-6">

                    <ManufacturerPriceTable
                        manufacturerPriceSettings={manufacturerPriceSettings}
                        setManufacturerPriceSetting={handleSetManufacturerPriceSetting}
                    />
        
                    {/* {specificPriceSettings.map((priceSetting) => (
                    <SpecificPriceSingle key={priceSetting.id} customerPriceConfigurationId={priceSettings.data?.data.customerPriceConfiguration?.id!} specificPriceSetting={priceSetting} />
                    ))} */}
        
                    {/* <SpecificPriceTable 
                    specificPriceSettings={specificPriceSettings} 
                    setSpecificPriceSetting={handleSetSpecificPriceSetting} 
                    /> */}
                
                </div>
            </div>
        </>
    )
}