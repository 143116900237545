import Card from "../shared/Card/Card";
import { useEffect, useState } from "react";
import usePriceSettings from "../../data/usePriceSettings";
import LoadingWheel from "../shared/LoadingWheel";
import SingleSalvagePrice from "./SingleSalvagePrice/SingleSalvagePrice";
import classNames from "../../utils/classNames";
import { BodyCondition, EngineCondition, ICustomerSalvagePriceSetting, IPriceSetting } from './../../models/PriceSettings';
import PriceFormat from "../../utils/priceFormat";
import { valueOrDefault } from "chart.js/dist/helpers/helpers.core";
import ManufacturerPriceSingle from "./Manufacturer/ManufacturerPriceSingle";

const singleSalvagePriceCell = (salvagePriceSettings?: ICustomerSalvagePriceSetting[], engineCondition?: EngineCondition, bodyCondition?: BodyCondition, colour?: string) => {

  let starting = 250;

  let value = starting;

  const enginePriceSetting = salvagePriceSettings?.find(x => x.engineCondition === engineCondition);
  const bodyPriceSetting = salvagePriceSettings?.find(x => x.bodyCondition === bodyCondition);

  if (!engineCondition || !enginePriceSetting || !bodyCondition || !bodyPriceSetting || enginePriceSetting.priceSetting?.isFixed || bodyPriceSetting.priceSetting?.isFixed) {

    if (engineCondition && enginePriceSetting) {
      value -= enginePriceSetting.priceSetting?.isFixed ?
        enginePriceSetting.priceSetting?.value :
        value * (enginePriceSetting.priceSetting?.value! / 100);
    };

    if (bodyCondition && bodyPriceSetting) {
      value -= bodyPriceSetting.priceSetting?.isFixed ?
        bodyPriceSetting.priceSetting?.value :
        value * (bodyPriceSetting.priceSetting?.value! / 100);
    };
  } else {
    const combinedPercentage = enginePriceSetting.priceSetting?.value! + bodyPriceSetting.priceSetting?.value!;
    value -= value * (combinedPercentage / 100);
  }


  return (
    <div className="flex flex-col items-center border p-4 rounded shadow-sm">
      <span className={classNames(colour ? colour : "", "text-xl font-bold")}>{PriceFormat(value)}</span>
    </div>
  )
}


export default function SalvagePriceSettings() {
  const { priceSettings } = usePriceSettings();
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (!priceSettings.isLoading) {
      // if (priceSettings.data?.data.salvagePriceSettings) reset(priceSettings.data?.data.salvagePriceSettings);
      setIsLoading(false);
    }
  }, [priceSettings.data, priceSettings.isLoading])

  if (isLoading) return <LoadingWheel />

  const gridHeaderCss = "text-center text-lg font-semibold pt-2";

  return (
    <div className="space-y-4">
      <Card>
        <div className="grid xl:grid-cols-2 p-4">
          <div className="flex flex-col items-center gap-y-4">
            <div className="w-full">
              <h1 className="font-semibold text-center mb-2">Engine Condition Deductions</h1>
              <hr />
            </div>

            <div className="flex items-center">
              <span className="md:pt-8 pr-2">-</span>
              <SingleSalvagePrice label={"Average"} salvagePriceSetting={priceSettings.data?.data.salvagePriceSettings?.find((s) => s.engineCondition === 1)} />
            </div>

            <div className="flex items-center">
              <span className="md:pt-8 pr-2">-</span>
              <SingleSalvagePrice label={"Bad"} salvagePriceSetting={priceSettings.data?.data.salvagePriceSettings?.find((s) => s.engineCondition === 2)} />
            </div>
          </div>

          <div className="flex flex-col items-center gap-y-4 xl:border-l-2">
            <div className="w-full">
              <h1 className="font-semibold text-center mb-2">Body Condition Deductions</h1>
              <hr />
            </div>

            <div className="flex items-center">
              <span className="md:pt-8 pr-2">-</span>
              <SingleSalvagePrice label={"Average"} salvagePriceSetting={priceSettings.data?.data.salvagePriceSettings?.find((s) => s.bodyCondition === 1)} />
            </div>

            <div className="flex items-center">
              <span className="md:pt-8 pr-2">-</span>
              <SingleSalvagePrice label={"Bad"} salvagePriceSetting={priceSettings.data?.data.salvagePriceSettings?.find((s) => s.bodyCondition === 2)} />
            </div>
          </div>
        </div>
      </Card>


      <Card>
        <h1 className="p-4">Example - Base Price £250</h1>
        <div className="p-4 grid grid-cols-4 lg:gap-2">

          <div className="">
            <h1 className={`${gridHeaderCss} text-right lg:pr-16`}>Engine</h1>
            <h1 className={gridHeaderCss}>Body</h1>
          </div>

          <h1 className={gridHeaderCss}>Good</h1>
          <h1 className={gridHeaderCss}>Average</h1>
          <h1 className={gridHeaderCss}>Bad</h1>


          <h1 className={gridHeaderCss}>Good</h1>
          {singleSalvagePriceCell(priceSettings.data?.data.salvagePriceSettings!, EngineCondition.NoFault, BodyCondition.Good, "text-green-500")}
          {singleSalvagePriceCell(priceSettings.data?.data.salvagePriceSettings!, EngineCondition.MinorFault, BodyCondition.Good, "text-amber-500")}
          {singleSalvagePriceCell(priceSettings.data?.data.salvagePriceSettings!, EngineCondition.MajorFault, BodyCondition.Good, "text-red-500")}


          <h1 className={gridHeaderCss}>Average</h1>
          {singleSalvagePriceCell(priceSettings.data?.data.salvagePriceSettings!, EngineCondition.NoFault, BodyCondition.Average, "text-amber-500")}
          {singleSalvagePriceCell(priceSettings.data?.data.salvagePriceSettings!, EngineCondition.MinorFault, BodyCondition.Average, "text-amber-500")}
          {singleSalvagePriceCell(priceSettings.data?.data.salvagePriceSettings!, EngineCondition.MajorFault, BodyCondition.Average, "text-red-500")}


          <h1 className={gridHeaderCss}>Bad</h1>
          {singleSalvagePriceCell(priceSettings.data?.data.salvagePriceSettings!, EngineCondition.NoFault, BodyCondition.HeavyDamage, "text-red-500")}
          {singleSalvagePriceCell(priceSettings.data?.data.salvagePriceSettings!, EngineCondition.MinorFault, BodyCondition.HeavyDamage, "text-red-500")}
          {singleSalvagePriceCell(priceSettings.data?.data.salvagePriceSettings!, EngineCondition.MajorFault, BodyCondition.HeavyDamage, "text-red-500")}

        </div>
      </Card>


    </div>
  )
}