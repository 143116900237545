import { Switch } from "@headlessui/react";
import classNames from "../../utils/classNames";
import { IPostcodePrice } from "../../models/PostcodeGroup";
import { useEffect, useState } from "react";
import usePostcodePriceMutations from "../../data/usePostcodePriceMutations";
import useDefaultCRUDHandlers from './../../hooks/useDefaultCRUDHandlers';

export default function EditPostcodeAreaBody({ postcodeArea }: { postcodeArea: IPostcodePrice }) {
    const { update } = usePostcodePriceMutations();
    const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Postcode Area");
    const [body, setBody] = useState<boolean>(postcodeArea.onlyGoodBodyCondition);

    useEffect(() => {
        if(postcodeArea.onlyGoodBodyCondition === body) return;
        setBody(postcodeArea.onlyGoodBodyCondition);
    }, [postcodeArea.onlyGoodBodyCondition]);

    const handleBodyChange = (checked : boolean) => {
        if (postcodeArea.onlyGoodBodyCondition === checked) return;
        setBody(checked);
        setTimeout(() => {
            update.mutate({
                ...postcodeArea,
                onlyGoodBodyCondition: checked
            }, {
                ...saveHandlers
            });
        }, 200);
        
    }
    
    return (
        <Switch
            checked={body}
            onChange={(e) => handleBodyChange(e)}
            className={classNames(
                body ? 'bg-primary-600' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
            )}
        >
            <span
            aria-hidden="true"
            className={classNames(
                body ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
            />
        </Switch>
    )
}